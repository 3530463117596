export default {
  "client": "LearningForward",
  "bannerImage": require("../../../img/mocks/learningforward_hero.png"),
  "productImageSmall": require("../../../img/mocks/learningforward_small.png"),
  "product": "RedesignPD Community",
  "services": [
    "Product Design",
    "Project Management",
    "Software Development"
  ],
  "problemsSolved": [],
  "highlights": [
    {
      "timeline": "9 week timeline",
      "designMeta": "7 user feedback sessions",
      "developmentMeta": "Built on open source project"
    }
  ],
  "timeLine": [
    {
      "date": "Feburary 2017",
      "summary": "Project kick off",
      "icon": "hourglass-start",
      "iconColor": "gray"
    },
    {
      "date": "March 2017",
      "summary": "User feedback and high fidelity designs",
      "icon": "pencil",
      "iconColor": "blue"
    },
    {
      "date": "March 2017",
      "summary": "Development sprints begin",
      "icon": "graduation-cap",
      "iconColor": "green"
    },
    {
      "date": "April 2017",
      "summary": "Product delivered",
      "icon": "group",
      "iconColor": "dark"
    }
  ]
}
