import Lightbox from 'react-image-lightbox';
import React, {Component} from 'react';

import CaseStudy from '../../../components/CaseStudy'
import learningforward from './_learningforward'
import landingpage from './final/lf-landingpage.png';
import dashboard from './final/lf-dashboard.png';
import team from './final/lf-teamassessment.png';
import lf1 from './ideation/lf-1.jpg';
import lf2 from './ideation/lf-2.jpg';
import lf3 from './ideation/lf-3.jpg';
import lf4 from './ideation/lf-4.jpg';

import '../content.scss';

const IMAGES = {
  'final': [
   landingpage,
   dashboard,
   team
  ],
  'ideation': [
    lf1,
    lf2,
    lf3,
    lf4,
  ],
};


export default class LearningforwardContent extends Component {
  state = {
    photoIndex: 0,
    open: false,
    photoCategory: null,
  }

  handleClose = () => {
    this.setState({
      open: false,
      category: null,
    });
  }

  handleOpen = (index, category) => {
    this.setState({
      open: true,
      photoIndex: index,
      photoCategory: category,
    });
  }

  handleNext = (index) => {
    this.setState({
        photoIndex: index
    })
  }

  handlePrev = (index) => {
    this.setState({
      photoIndex: index
    });
  }

  generateLightBox = (category) => {
    const {photoCategory, photoIndex, open,} = this.state;
    const imageButtons = IMAGES[category].map((imageUrl, index) => {
      return(
        <div className="lightbox-button">
          <button
            type="button"
            onClick={this.handleOpen.bind(null, index, category)}
            style={{backgroundImage: 'url(' + imageUrl + ')' }}
          />
        </div>
      );
    });
    return (
      <div>
        <div className="lightbox-button-array">
          {imageButtons}
        </div>
        {open && (photoCategory === category) &&
          <Lightbox
            mainSrc={IMAGES[category][photoIndex]}
            nextSrc={IMAGES[category][(photoIndex + 1) % IMAGES[category].length]}
            prevSrc={IMAGES[category][(photoIndex + IMAGES[category].length - 1) % IMAGES[category].length]}
            onCloseRequest={this.handleClose}
            onMovePrevRequest={this.handlePrev.bind(
              null,
              (photoIndex + IMAGES[category].length - 1) % IMAGES[category].length)
            }
            onMoveNextRequest={this.handleNext.bind(null, (photoIndex + 1) % IMAGES[category].length)}
          />
        }
      </div>
    );
  };
  render() {
    return (
      <CaseStudy caseStudy={learningforward}>
        <h1>PD: Better, Faster, & All in One Place</h1>
        {this.generateLightBox('final')}
        <p>
          The quality of learning in a classroom is measured by its teachers, and the quality of an educator is
          influenced by their professional development. Recognizing this, Learning Forward created a pioneering process
          for school districts to measure a district's PD capabilities. We partnered with Learning Forward to build a
          fast, easy, and efficient tool to leverage their powerful assessment process. We were able to extend an
          existing open source assessment tool built in partnership with The Bill & Melinda Gates Foundation to provide
          more value in less time.
        </p>
        <h2>The Process</h2>
        <h3 className="text-muted">Inspiration</h3>
        <p>
          Learning Forward is known for creating high quality content, and they wanted to embed their content in a
          digital tool to scale their process. Their vision would allow reduced time in evaluating current state while
          enabling improved community discussions. The high quality data would also unlock comprehensive analysis of
          this professional development data.
        </p>
        <p>
          After seeing the success of a previous collaboration and assessment tool we built, PDredesign, they decided
          that working with Mobility Labs would be a perfect fit.
        </p>
        <h3 className="text-muted">Ideation</h3>
          {this.generateLightBox('ideation')}
        <p>
          In discussing the project with Learning Forward it became apparent that the process was very content
          heavy. We needed to define: a small number of page types and UI elements, then mix-and-match them depending
          on each sections need. This would lead to improved understanding and retention.
        </p>
        <p>
          We worked closely together to refine and condense a previously human-guided process into a self-explanatory
          application. The flow became 7 unique steps. During the sketching sessions with Learning Forward, we
          concluded that making these steps linear would ease confusion. We created helpful UI elements to track
          progress thru these steps at the insistence of our subject matter experts.
        </p>
        <p>
          The colors and typography were chosen to resemble the client's existing brand identity in order to tie the
          application to, and build upon, Learning Forward's reputation within the education industry.
        </p>
        <h3 className="text-muted">Implementation</h3>
        <p>
          Rather than create mockups in software like Photoshop or Illustrator, we iterated quickly on the low fidelity
          designs by designing the final visual aesthetic with live HTML/CSS. By leveraging online code environment
          CodePen, the team was able to produce high-fidelity mock ups and production ready front-end code, which
          became immediately available to our back-end team. This decision enabled all teams to move quickly and stay
          in-sync, without compromising design vision.
        </p>
        <p>
          To maximize value for Learning Forward and end users, we built the application on existing open source
          solutions, rather than starting from scratch. This allowed us to implement more collaboration and
          communication features than would normally be possible given the timeline.
        </p>
      </CaseStudy>
    )
  }
}
